const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://e39ydvh896.execute-api.us-west-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://cg7kbw27h9.execute-api.us-west-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.dev.gromit.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'Australia/Brisbane'
  },
  reactApp: {
    VERSION: 'DO-2460-combined-aurora-db-solution',
    HOSTNAME: 'https://targets.dev.gromit.forwoodsafety.com'
  }
};

export default config;